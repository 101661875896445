<template>
  <ion-page id="page-content">
    <ion-content :fullscreen="true">
      <topbar :bigtitle="true" :class="{ 'letter-ios-14': $store.state.deviceType == 1 }" title="Privacy Policy" :showBack="true"></topbar>
      <div class="pc-box big pc-box2">
        <div class="pc-min-content-pct">
          <div class="title-PC2" v-if="$store.state.isPC">Privacy Policy</div>
          <div class="content">
            <div v-for="(item, index) in $store.state.moreInfo.policy" :key="index">
              <p class="font-16-24 color-66 m-b-30 Medium" v-if="item.type == 2" @click="clickEvent" v-html="item.content"></p>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import topbar from "@/components/topbar.vue";
import config from "../config";
export default {
  name: "privacyPage",
  mixins: [config.globalMixin],
  components: { IonContent, IonPage, topbar },
  data() {
    return {
      eventList: [],
    };
  },
  methods: {
    clickEvent(e) {
      console.log(e.target.className);
      this.eventList.forEach(item => {
        if (e.target.className == item.className) {
          if (item.link.search("http") > -1 || item.link.search("www") > -1 || item.link.search("com") > -1) {
            window.open(item.link);
          } else {
            this.$router.push({
              path: item.link,
            });
          }
        }
      });
    },
    backEvent() {
      this.$router.push({
        path: "/main/more",
      });
    },
  },
  ionViewDidEnter() {
    this.$store.state.moreInfo.policy.forEach(item => {
      if (item.type == 3) {
        this.eventList = item.eventList;
      }
    });
  },
  ionViewWillEnter() {
    this.$store.state.exitApp = false;
    this.sendGAPageView("Privacy Policy");
  },
};
</script>
<style lang="scss">
.subTitle {
  margin: 20px 0 10px;
  font-family: "Helveticaneue-Medium";
  strong {
    font-weight: 500;
    font-family: "Helveticaneue-Bold";
  }
  span {
    font-size: 18px !important;
  }
  &:nth-child(1) {
    span {
      font-size: 22px !important;
    }
  }
}
</style>
